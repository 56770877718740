import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonChevronIcon, ScrollLeftButton, ScrollRightButton } from 'src/domains/layouts/shared/ScrollButtons.style';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';
import { LazyLoadingImg } from 'src/domains/layouts/webview/components/common/lazyLoadingImage/LazyLoadingImg';
import { positionElementFull } from 'src/domains/layouts/styles/Mixin.style';
import { isNewDesign } from 'src/utils/new-design';

interface HomePageCarouselPromoPropsType {
    bgColor?: string;
    bgImage?: string;
    btnColor?: string;
    calcShift: number;
    isClickable: boolean;
    isDot?: boolean;
    isHomePage?: boolean;
    isOneElem?: boolean;
    isSelected?: boolean;
    isSuspended?: boolean;
    itemsInRow?: number;
}

type HomePageCarouselPromosComponentType = Pick<HomePageCarouselPromoPropsType, 'isHomePage' | 'isDot'>;

export const HomePageCarouselPromosComponent = withConfig(theme => styled('div', { label: 'HomePageCarouselPromosComponent' })<HomePageCarouselPromosComponentType>`
    background-color: ${({ isHomePage }): string => isHomePage === true ? theme.star.homePageCarousel.items.bgColor : theme.star.homePageCarousel.items.bgColorQuaternary};
    height: 216px;
    padding: ${({ isHomePage }): string => isHomePage === true ? '8px 0 0 8px' : '8px 0 0 0'};
    @media ${theme.star.mediaQuery.tablet} {
        height: ${({ isDot }): string => isDot === true ? '248px' : '216px'};
    }
`);

export const HomePageCarouselPromosListWrapper = styled('div', { label: 'HomePageCarouselPromosListWrapper' })`
    height: 100%;
    position: relative;
    width: 100%;
    overflow: hidden;
`;

type SliderWrapperType = Pick<HomePageCarouselPromoPropsType, 'calcShift'>;

export const SliderWrapper = styled('div', { label: 'SliderWrapper' })<SliderWrapperType>`
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 1s ease-out;
    transform: ${({ calcShift }): string => `translateX(${calcShift}px)`};
`;

type HomePageCarouselSingleItemType = Pick<HomePageCarouselPromoPropsType, 'bgColor' | 'bgImage'>;

export const HomePageCarouselSingleItem = withConfig(theme => styled('div', { label: 'HomePageCarouselSingleItem' })<HomePageCarouselSingleItemType>`
    background-color: ${({ bgColor }): string => bgColor !== undefined && bgColor !== '' ? bgColor : theme.star.homePageCarousel.items.bgColorTertiary};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 200px;
    margin-left: 8px;
    overflow: hidden;
    position: relative;
    width: 300px;
    z-index: ${theme.star.zIndexGlobal.base};
    &:first-of-type {
        margin-left: 0;
    }
`);

export const LazyImageItem = withConfig(theme => styled(LazyLoadingImg, { label: 'LazyImageItem' })`
    ${positionElementFull};
    position: absolute;
    z-index: ${theme.star.zIndexGlobal.below};
`);

export const HomePageCarouselBettingItem = styled(HomePageCarouselSingleItem, { label: 'HomePageCarouselBettingItem' })`
    align-items: flex-start;
    justify-content: flex-end;
`;

export const CardBodyWrapper = styled('div', { label: 'CardBodyWrapper' })`
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 0%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    padding: 8px 72px 0 16px;
    white-space: pre-line;
`;

export const CardBodyTitle = withConfig(theme => styled('h2', { label: 'CardBodyTitle' })`
    ${isNewDesign() ? null : "font-family: Gentona, sans-serif"};
    color: ${theme.star.homePageCarousel.items.txtColor};
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
`);

export const CardBodySubTitle = withConfig(theme => styled('p', { label: 'CardBodySubTitle' })`
    ${isNewDesign() ? null : "font-family: Gentona, sans-serif"};
    color: ${theme.star.homePageCarousel.items.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.xMedium};
    line-height: 1.2;
    margin: 4px 0 0;
`);

export const CardButtonWrapper = styled('div', { label: 'CardButtonWrapper' })`
    margin-bottom: 16px;
    padding: 0 16px;
`;

type CardButtonType = Pick<HomePageCarouselPromoPropsType, 'btnColor'>;

export const CardButton = withConfig(theme => styled(Button, { label: 'CardButton' })<CardButtonType>`
    ${({ btnColor }): string => btnColor === undefined ? '' : `background-color: ${btnColor}`};
    ${theme.star.buttons.tertiary.dark.txtColorCarousel === '' ? '' : `color: ${theme.star.buttons.tertiary.dark.txtColorCarousel}`};
`);

export const CardBettingWrapper = styled('div', { label: 'CardBettingWrapper' })`
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 0%;
    justify-content: flex-end;
    padding: 0 16px;
    white-space: pre-line;
    width: 100%;
`;

export const CardBodyActionLink = styled('div', { label: 'CardBodyActionLink' })`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
`;

type CardBodyActionWrapperType = Pick<HomePageCarouselPromoPropsType, 'isClickable'>;

export const CardBodyActionWrapper = styled('div', { label: 'CardBodyActionWrapper' })<CardBodyActionWrapperType>`
    cursor: ${({ isClickable }): string => isClickable ? 'pointer' : 'default'};
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const CardFooterWrapper = withConfig(theme => styled('div', { label: 'CardFooterWrapper' })`
    background-color: ${theme.star.homePageCarousel.items.bgColorSecondary};
    color: ${theme.star.homePageCarousel.items.txtColorSecondary};
    flex: 0 0 28px;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.2;
    padding: 8px;
    width: 100%;
`);

export const CardFooterText = styled('div', { label: 'CardFooterText' })`
    > * {
        margin: 0;
    }
`;

export const TcApplied = styled('div', { label: 'TcApplied' })`
    cursor: pointer;
`;

const carouselScrollButtonStyle = (theme: EmotionTheme): SerializedStyles => {
    return css`
        background-color: ${theme.star.homePageCarousel.controls.bgColor};
        bottom: 0;
        margin: auto 0;
        top: 0;
        @media ${theme.star.mediaQuery.tablet} {
            bottom: 40px;
        }
    `;
};

export const ScrollLeft = withConfig(theme => styled(ScrollLeftButton, { label: 'ScrollLeft' })`
    ${carouselScrollButtonStyle(theme)};
    z-index: initial;
    left: 16px;
`);

export const ScrollRight = withConfig(theme => styled(ScrollRightButton, { label: 'ScrollRight' })`
    ${carouselScrollButtonStyle(theme)};
    right: 16px;
`);

export const ChevronIcon = withConfig(theme => styled(ButtonChevronIcon, { label: 'ChevronIcon' })`
    fill: ${theme.star.homePageCarousel.controls.bgColorSecondary};
`);

type SelectionsWrapperType = Pick<HomePageCarouselPromoPropsType, 'isOneElem'>;

export const SelectionsWrapper = styled('div', { label: 'SelectionsWrapper' })<SelectionsWrapperType>`
    display: flex;
    padding: 16px;
    width: ${({ isOneElem }): string => isOneElem === true ? 'auto' : '100%'};
`;

type SelectionPromoWrapperType = Pick<HomePageCarouselPromoPropsType, 'isSelected' | 'isSuspended' | 'itemsInRow'>;

export const SelectionPromoWrapper = withConfig(theme => styled('div', { label: 'SelectionPromoWrapper' })<SelectionPromoWrapperType>`
    align-items: center;
    background-color: ${({ isSelected, isSuspended }): string => isSuspended === true ? theme.star.selection.bgColorSenary : isSelected === true ? theme.star.selection.bgColorQuinary : theme.star.selection.bgColor};
    color: ${({ isSelected, isSuspended }): string => isSuspended === true ? theme.star.selection.txtColorSecondary : isSelected === true ? theme.star.selection.txtColorTertiary : theme.star.selection.txtColor};
    cursor: pointer;
    display: flex;
    flex: ${({ itemsInRow }): string => itemsInRow === 2 ? '1 1 0%' : '0 0 0%'};
    justify-content: space-between;
    margin-left: 4px;
    min-width: 86px;
    overflow: hidden;
    padding: ${({ itemsInRow }): string => itemsInRow === 1 ? '0' : '8px'};
    text-overflow: ellipsis;
    &:first-of-type {
        margin-left: 0;
    }
`);

// TODO: Remove this production issue fix after changing for proper colors
export const SelectionText = withConfig(theme => styled('div', { label: 'SelectionText' })<{isSelected: boolean; isPricedUp: boolean}>`
    color: ${({ isSelected, isPricedUp }): string => isPricedUp ? isSelected ? theme.star.selection.txtColorTertiary : theme.star.selection.txtColor : theme.star.homePageCarousel.items.txtColorTertiary};
    flex: 1 1 auto;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    overflow: hidden;
    padding-right: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
`);

type SelectionPriceType = Pick<HomePageCarouselPromoPropsType, 'isSelected' | 'isSuspended' | 'isOneElem'>;

export const SelectionPrice = withConfig(theme => styled('div', { label: 'SelectionPrice' })<SelectionPriceType>`
    ${({ isOneElem }): string => isOneElem === true ? 'min-width: 87px' : ''};
    background-color: ${({ isSelected, isSuspended }): string => isSuspended === true ? theme.star.selection.bgColorSenary : isSelected === true ? theme.star.selection.bgColorQuinary : theme.star.selection.bgColor};
    color: ${({ isSelected, isSuspended }): string => isSuspended === true ? theme.star.selection.txtColorSecondary : isSelected === true ? theme.star.selection.txtColorTertiary : theme.star.selection.txtColor};
    cursor: pointer;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    height: 100%;
    line-height: 1.3334;
    padding: ${({ isOneElem }): string => isOneElem === true ? '8px 16px' : '0'};
    text-align: center;
    &:only-of-type {
        width: 100%;
    }
`);

import { MethodType, GenerateUrlApiType, ResponseType, ParamsFetchType } from 'src_common/browser/apiUtils';
import { GenerateUrlApiParamsType } from 'src_common/server/webDriver/sdkApiWebUtils';
import {
    openapi_website_cms_getActiveHomePageCarousel,
    decodeResponse200,
    Response200Type,
} from 'src/api_openapi/generated/openapi_website_cms_getActiveHomePageCarousel';
import { CarouselModeType } from 'src/domains/layouts/state/cmsState/homePageCarouselState/HomePageCarouselState';
import { EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';

export type ActiveHomePageCarouselType = {
    action_template?: null | undefined | string;
    bg_color?: null | undefined | string;
    btn_color?: null | undefined | string;
    btn_link?: null | undefined | string;
    btn_title?: null | undefined | string;
    carousel_type: string;
    competition_id?: null | undefined | number;
    date_from: string;
    date_to: string;
    display_order: number;
    event_id?: null | undefined | EventId;
    game_id?: null | undefined | number;
    id: number;
    img_url?: null | undefined | string;
    is_active: boolean;
    is_desktop: boolean;
    is_mobile: boolean;
    market_id?: null | undefined | MarketId;
    media_type: string;
    selections_details: unknown;
    sport?: null | undefined | string;
    subtitle: string;
    tc_text?: null | undefined | string;
    title: string;
    universe: string;
};

interface ParamsType {
    carousel_type: CarouselModeType;
}

export const getActiveHomePageCarousel = {
    browser: {
        params: (params: ParamsType): ParamsFetchType<ParamsType> => {
            return {
                type: MethodType.GET,
                url: `/api-web/cms/active-home-page-carousel/${params.carousel_type}`,
            };
        },
        decode: (status: number, data: ResponseType): Response200Type => {
            if (status === 200 && data.type === 'json') {
                return decodeResponse200(data.json);
            }

            throw new Error(`unhandled response ${status} - ${data.type}`);
        },
    },
    express: {
        method: MethodType.GET,
        urlBrowser: '/api-web/cms/active-home-page-carousel/:carousel_type',
    },
    generateUrlApi: async (params: GenerateUrlApiParamsType<ParamsType>): Promise<GenerateUrlApiType> => {
        const resp = await openapi_website_cms_getActiveHomePageCarousel(
            params.API_URL,
            params.API_TIMEOUT,
            params.jwtToken,
            {
                universe: params.API_UNIVERSE,
                carousel_type: params.req.params.carousel_type,
            }
        );

        return {
            passToBackend: false,
            status: resp.status,
            responseBody: resp.body,
        };
    },
};
